<template>
  <div v-html="parsedMarkdown"></div>
</template>

<script>
import { marked } from "marked";
import hljs from "highlight.js";
export default {
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },

  computed: {
    parsedMarkdown() {
      return marked.parse(this.markdown, {
        highlight: function (markdown) {
          return hljs.highlightAuto(markdown).value;
        },
      });
    },
  },
  mounted() {
    hljs.highlightAll();
  },
};
</script>
<style>
@import "~highlight.js/styles/monokai-sublime.css";
</style>
