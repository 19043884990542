<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    :title="title"
    content-class="primary-modal"
    dialog-class="primary-dialog"
    :cancel-variant="cancelVariant"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    centered
    :no-close-on-esc="noCloseOnEsc"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :hide-header-close="hideHeaderClose"
    @ok="ok()"
    @cancel="cancel()"
  >
    <slot> </slot>
  </b-modal>
</template>
<script>
export default {
  name: "PrimaryModal",
  props: {
    title: {
      type: String,
      default: "Prompt Modal",
    },
    modalId: {
      type: String,
      required: true,
    },
    okTitle: {
      type: String,
      default: "Yes",
    },
    cancelTitle: {
      type: String,
      default: "No",
    },
    cancelVariant: {
      type: String,
      default: "secondary text-white",
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    hideHeaderClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ok() {
      this.$emit("ok");
    },
    cancel() {
      this.$emit("cancel")
    }
  },
};
</script>
