<template>
  <div class="mcq px-5 py-4">
    <div>
      <div class="mcq__question">
        <BaseMarkdown :markdown="mcq.question" />
      </div>

      <div class="mcq__options d-flex flex-column mt-3">
        <div
          v-for="(value, key, i) in mcq.options"
          id="option"
          :key="i"
          class="border py-3 px-4 my-2 m-rounded-5"
          :class="{ 'mcq__options--active': activeOptions.includes(key) }"
          @click="setActive(key)"
        >
          <span>{{ `${key}: ${value}` }}</span>
        </div>
      </div>
    </div>

    <div class="mcq__button d-flex justify-content-end">
      <b-button
        variant="primary"
        class="px-5 mt-3 btn-primary"
        :class="classObject"
        @click="next"
      >
        {{ isBusy ? "" : "Next" }} <RingLoader v-if="isBusy" />
      </b-button>
    </div>
  </div>
</template>

<script>
import RingLoader from "@/components/loader/RingLoader";

import BaseMarkdown from "@/components/BaseMarkdown";
export default {
  components: {
    RingLoader,
    BaseMarkdown,
  },
  props: {
    mcq: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeOptions: [],
    };
  },
  computed: {
    classObject() {
      return {
        "d-flex": this.isBusy,
        "align-items-center": this.isBusy,
        "justify-content-center": this.isBusy,
      };
    },
  },
  watch: {
    mcq() {
      this.activeOptions = [];
    },
  },
  methods: {
    setActive(key) {
      if (this.mcq.answer_type === "single") {
        this.activeOptions = !this.activeOptions.includes(key) ? [key] : [];
      }

      if (this.mcq.optionType === "multiple") {
        !this.activeOptions.includes(key)
          ? this.activeOptions.push(key)
          : (this.activeOptions = this.activeOptions.filter((i) => {
              return i !== key;
            }));
      }
    },
    next() {
      this.$emit(
        "next",
        this.activeOptions.reduce((cur, key) => {
          return Object.assign(cur, { [key]: this.mcq.options[key] });
        }, {})
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mcq {
  &__question {
    font-size: 1.5rem;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  &__options {
    font-size: 1.14rem;
    font-weight: 500;
    color: #afb9c6;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
    }

    &--active {
      background-color: #cee7fd;
      color: #1e3b59;
      border: 0.0625rem solid #08569d !important;
    }
  }

  &__button {
    font-size: 1.14rem;
    font-weight: 500;
  }
}
</style>
