<template>
  <div
    class="test-timer d-flex align-items-center justify-content-center p-3 border m-rounded-10"
  >
    <div>
      <strong
        class="test-timer__count-down font-weight-bold d-flex justify-content-center"
        :class="classObject"
      >
        {{ showTime }}
      </strong>
      <p class="test-timer__description d-flex justify-content-center fs-12">
        Time Remaining
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
    paused: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      secs: this.time,
      theInterval: "",
    };
  },
  computed: {
    showTime() {
      return this.formattedTime(this.secs);
    },
    percentageUsed() {
      return this.duration > 0 ? (this.secs / this.duration) * 100 : 100;
    },
    classObject() {
      return {
        "text-success": this.percentageUsed > 50 && this.percentageUsed !== 100,
        "text-warning": this.percentageUsed < 50,
        "text-danger": this.percentageUsed < 30,
      };
    },
  },
  watch: {
    time() {
      this.secs = this.time;
      this.countdown();
    },
    paused() {
      this.countdown()
    }
  },
  created() {
    this.countdown();
  },
  methods: {
    countdown() {
      clearInterval(this.theInterval);

      if (this.secs > 0 && !this.paused) {
        this.theInterval = setInterval(() => {
          this.secs--;
          if (this.secs === 0) {
            clearInterval(this.theInterval);
          }
        }, 1000);
      }
    },
    formattedTime(secs) {
      const hr = 3600;
      const min = 60;
      let hrs = Math.floor(secs / hr);
      secs = secs % hr;
      let mins = Math.floor(secs / min);
      secs = secs % min;

      return `${hrs}h:${mins}m:${secs}s`;
    },
  },
};
</script>

<style lang="scss" scoped>
.test-timer {
  background-color: $white;
  margin: 0 4.5rem;
  @include media-breakpoint-down(sm) {
    margin: 0 2.5rem;
  }

  &__count-down {
    color: $dark-blue;
    font-size: 2.25rem;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
      font-weight: 400;
    }

    @include media-breakpoint-down(xs) {
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  &__description {
    color: $grey-tint-7;
    @include media-breakpoint-down(xs) {
      font-size: 0.7rem;
    }
  }
}
</style>
