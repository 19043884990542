<template>
  <div class="skill-test">
    <div class="skill-test__timer dashboard-summary">
      <TestTimer :time="mcq.left_time" :duration="mcq.duration" />
    </div>
    <div class="dashboard-content">
      <div
        class="skill-test__mcq d-flex flex-column justify-content-center border m-rounded-10 mt-4 mb-5"
      >
        <div class="skill-test__header d-flex justify-content-between py-3 px-4">
          <span class="skill-test__title">{{
            mcq.questions > 0 ? `${mcq.skill.name} MCQ Quiz` : "Getting question..."
          }}</span>
          <span v-if="!mcqHasEnded" class="skill-test__number">{{
            `${currentIndex} / ${mcq.questions}`
          }}</span>
        </div>

        <div class="skill-test__progress">
          <b-progress height="0.2rem" :value="attempted" max="100"></b-progress>
        </div>

        <div v-if="!mcqHasEnded">
          <MCQ :mcq="mcq.current_question" :is-busy="isBusy" @next="next" />
        </div>
        <div v-if="mcqHasEnded" class="py-5 text-center">
          <p class="m-fs-25 font-weight-bold m-min-h-200">{{ isBusy ? "Buffering..." : 'Test has ended!' }}</p>
        </div>
      </div>
    </div>
    <prompt-modal
      modal-id="mcq_prompt"
      title="Mcq has ended"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Continue Assessment"
      cancel-title="Back To Skills"
      @ok="continueAssessment()"
      @cancel="$router.push('/skills')"
    >
      <p class="m-fs-16 text-center">
        You may go back to the skills page or continue to the assessment details page to
        view your result.
      </p>
    </prompt-modal>
  </div>
</template>

<script>
import TestTimer from "../../components/skills/TestTimer.vue";
import MCQ from "../../components/skills/MCQ.vue";
import PromptModal from "@/components/PromptModal.vue";

import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {
    TestTimer,
    MCQ,
    PromptModal,
  },
  data() {
    return {
      isBusy: true,
    };
  },
  computed: {
    ...mapState({
      mcq: (state) => state.skill.mcq,
    }),
    attempted() {
      return (this.mcq.attempted_questions / this.mcq.questions) * 100;
    },
    currentIndex() {
      return this.mcq.attempted_questions + 1;
    },
    mcqHasEnded() {
      return this.mcq.status > 0 || this.mcq.attempted > 0
    }
  },
    watch: {
    mcq(mcq) {
      if (mcq.status > 0 || mcq.attempted > 0) {
        this.$bvModal.show("mcq_prompt");
      }
    },
  },
  created() {
    let loader = this.$loading.show();

    this.fetchMcq(this.$route.params.id)
      .then(() => {
        this.subscribeToEvent(this.mcq.skill_talent_id);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
           this.$store.dispatch('setErrorStatus', err.response.status)
           this.$router.push(`/${err.response.status}`)
        }
      })
      .finally(() => {
        this.isBusy = false
        loader.hide();
      });
  },
  methods: {
    ...mapActions({
      fetchMcq: "skill/fetchMcq",
      nextQuestion: "skill/nextQuestion",
    }),
    ...mapMutations({
       setMcq: "skill/SET_MCQ"
    }),
    subscribeToEvent(skillTalentId) {
      Echo.private(`App.Models.Skills.SkillTalent.${skillTalentId}`).listen(
        `.mcq.completed`,
        (e) => {
          this.setMcq(e.mcq)
        }
      );
    },
    continueAssessment() {
      this.$router.push(`/skills/${this.$route.params.id}/assessment-details`);
    },
    next(selected) {
      this.isBusy = true;
      this.nextQuestion({ id: this.$route.params.id, selected: selected })
        .then(() => {
          //maybe do something here
        })
        .catch(() => {
          //do something here
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.skill-test {
  &__mcq {
    background-color: #fff;
    margin: 0 2rem;

    @include media-breakpoint-down(sm) {
      margin: 0 0.5rem;
    }
  }

  &__header {
    font-size: 1.5rem;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      font-size: 1rem;
    }
  }
}
</style>
